import React, {
  useEffect,
  useState,
} from 'react';
import { node } from 'prop-types';
import { ABContext } from '../../contexts/ABContext';
import isABTest from '../../ab/isabtest';

export const ABContextProvider = ({ children }) => {
  const [abTests, setABTests] = useState({});

  // HOT-384-dlp-ticket-step-change
  useEffect(() => {
    const HOT384IsVariant = isABTest('HOT-384');

    // Add context
    const testHOT384 = ({
      HOT384: {
        name: 'HOT-384',
        variant: HOT384IsVariant,
      },
    });

    // Update state
    setABTests((prevState) => ({ ...prevState, ...testHOT384 }));
  }, []);

  return (
    <ABContext.Provider value={abTests}>{ children }</ABContext.Provider>
  );
};

ABContextProvider.propTypes = {
  children: node.isRequired,
};

ABContextProvider.defaultProps = {};

export default ABContextProvider;
